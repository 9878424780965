import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import ReactHtmlParser from 'react-html-parser';
// Components
import Card from "../components/Card";

// Static Images

// Styles

import styles from "./CardList.module.scss";

var jobPostingData = [
    {
      id: 1,
      title: "First Stewardess | 100m+",
      description:
       "We are looking for a First Stewardess to join a 100m+ private motor yacht. ",
	  experience : "Head of Service on 100m+ yachts or as Chief Stew on smaller yachts. Strong service background. ",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: "182 Days Leave (3:3 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover.",
      startDate: "At the Earliest. ",
    },
	
	  {
      id: 2,
      title: "Service Stewardess | 100m+",
      description:
       "We are looking for a Service Stewardess to join a 100m+ private motor yacht. ",
	   experience : "One season on yachts in a service based role or strong shoreside hospitality background in high end hotels/restaurants preferred. ",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	 {
      id: 3,
      title: "Housekeeper | 100m+",
      description:
       "We are looking for a Housekeeper to join a 100m+ private motor yacht.",
	   experience : "One season on yachts with Housekeeping duties or relevant shoreside experience.",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	 {
      id: 4,
      title: "Deckhand | 60m+",
      description:
       "We are looking for a Deckhand to join a 60m+ private motor yacht.",
	   experience : "One season on yachts as Deckhand.",
	  certification : "Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	
	{
      id: 5,
      title: "Motorman | 100m+",
      description:
       "We are looking for a Motorman to join a 100m+ private motor yacht.",
	   experience : "Yacht/cruise ship experience preferred.",
	  certification : "Engine Rating Cert, Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
	{
      id: 6,
      title: "Housekeeper / Yoga Instructor | 100m+",
      description:
       "We are looking for a Housekeeper/Yoga Instructor to join a 100m+ private motor yacht.",
	   experience : "Experience teaching yoga classes. Yacht/cruise ship experience is beneficial.",
	  certification : "Yoga Teacher Qualification, Valid STCW and ENG1 Medical as a minimum.",
      currency: "Package",
      salary: " 122 Days Leave (4:2 Rotation), Salary paid in EUR, Flights and 24/7 Medical Cover. ",
      startDate: "At the Earliest. ",
    },
    
  ];

const HomeCardList = (props) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 1,
          delay: 0.2,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: -100,
        opacity: 0,
      });
    }
  }, [inView]);

  var settings = {
    className: "center",
    centerMode:true,
    centerPadding:"30px",
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:true
  };
  
  return (
    <motion.article ref={ref} animate={animation} className={styles.card_grid}>
	<Slider {...settings}>
      {props.items.map((item) => {
        console.log(item);
        return (
		 
          <Card
            key={item.id}
            id={item.id}
            title={item.title}
             description={ReactHtmlParser(item.description)}
			 experience={item.experience}
			  certification={item.certification}
            currency={item.currency}
            salary={item.salary}
            startDate={`Starting ${item.startDate}`}
            linkTo={item.apply_url}
            icon={null}
            iconColor={null}
            cardColor={styles.card_bg}
            image={item.image || null}
            cardStyle={item.style}
          // linkTo={`/job-posting/${item.id}`}
          // onClick={props.onDeletePost}
          />
        );
      })}
	  </Slider>
    </motion.article>
	
  );

  
};

export default HomeCardList;
